$profile-color-primary: #72B1C9;
$profile-color-primary-light: lighten($profile-color-primary, 20%);

$profile-color-secondary: #F05B2A;

$profile-color-error: #a94442;

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee
$gray-lightest:          #d0d0d0;

$form-green:             #7ea700;
$brand-green:            #5FB785;