/* You can add global styles to this file, and also import other style files */
@import "mpact-variables";
$font-family-base:
  proxima-nova,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@import "ngx-progressive-image-loader/ngx-progressive-image-loader";

$scale: 1; // the scale of loading image in place holder
$transition: opacity 0.75s linear; //, transform 1s linear; // the transition of loading image in place holder, you could try: opacity .3s ease
@include progressive-image-loader($scale, $transition);

@import "quill/dist/quill.core.css";
@import "quill/dist/quill.bubble.css";
@import "quill/dist/quill.snow.css";

body {
  height: initial !important;
  // background-color: #424854;
}

a {
  color: #212529;

  &:hover {
    color: #3e454d;
  }
}

strong {
  font-weight: 700;
}

#desc-wrapper {
  -webkit-animation: feature-text-anim 0.75s ease-in-out;
  animation: feature-text-anim 0.75s ease-in-out;
  position: relative;
  width: 100%;
  max-width: 1170px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // margin-top: 110px;
  padding: 20px 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  margin-left: auto;
  margin-right: auto;

  .row {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  p {
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    letter-spacing: 1px;
    text-transform: none;
    line-height: 1.4em;
    color: #eee;
    margin: 0 20px 20px;

    @media only screen and (max-width: 640px) {
      font-size: 18px;
    }

    & > strong {
      padding-top: 50px;
      display: block;
      font-weight: 400;
      letter-spacing: 0px;
      text-transform: none;
      font-weight: 600;
      font-style: normal;
      font-size: 52px;
      letter-spacing: 4px;
      text-transform: uppercase;
      line-height: 1em;
      color: #fff;
      @media only screen and (max-width: 640px) {
        font-size: 30px;
      }
    }

    &:last-child > a {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 1px;
      font-style: normal;
      font-size: 15px;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-decoration: none;
      padding: 1em 1.75em;
      background-color: #43c59e;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      line-height: 1em;
      margin: 10px 0;
      color: #fff;
      border: none;
      transition: all 0.1s ease-in-out;

      &:hover {
        background-color: rgba(67, 197, 158, 0.8);
      }
    }
  }
}

@media (min-width: 991px) {
  #desc-wrapper {
    text-align: center;
    max-width: 1170px;
  }
}

@media (max-width: 768px) {
  #desc-wrapper {
    text-align: left;
    max-width: 956px;
  }
}

.takeMpactbtn {
  border-radius: 3px;
  background-color: #43c59e;
  border-color: #43c59e;
  border-width: 2px;
  border-style: solid;
  transition:
    0.14s background-color linear,
    0.14s color linear;
  padding: 1em 1.75em;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  opacity: 1;

  &:hover {
    background-color: #69ccae;
    border-color: #69ccae;
    text-decoration: none;
  }
}

.btn-mpact {
  color: #fff;
  background-color: #43c59e;
  border-color: #43c59e;

  &:hover {
    color: #fff;
    background-color: #69ccae;
    border-color: #69ccae;
    text-decoration: none;
  }

  &:disabled {
    background-color: #aaa;
    border-color: #aaa;

    &:hover {
      background-color: #aaa;
      border-color: #aaa;
    }
  }

  &-blue {
    color: #fff;
    background-color: #849ad0;
    border-color: #849ad0;

    &:hover {
      background-color: lighten(#849ad0, 5);
      border-color: lighten(#849ad0, 5);
      text-decoration: none;
      color: #fff;

      &:disabled {
        background-color: #aaa;
        border-color: #aaa;

        &:hover {
          background-color: #aaa;
          border-color: #aaa;
        }
      }
    }
  }
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 700px;
  overflow: hidden;
  height: 100%;
  // background: url("/assets/img/home/landingHeroBG-overlay.jpg") top center no-repeat;
  // background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1500' height='823' viewBox='0 0 1500 823'%3E%3Cfilter id='blur' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='20 20' edgeMode='duplicate' /%3E%3CfeComponentTransfer%3E%3CfeFuncA type='discrete' tableValues='1 1' /%3E%3C/feComponentTransfer%3E%3C/filter%3E%3Cimage filter='url(%23blur)' xlink:href='data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDABgWFiIYIjcgIDdCLyovQkA1NDQ1QEZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkb/2wBDARoiIiwmLDUhITVGNSw1RkZGOztGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkb/wAARCAAiAEMDACIAAREBAhEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAKhAAAQQBAwIFBAMAAAAAAAAAAQACESEDEjFBUWETIjJx8ARCgdEjofH/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAMAwAAARECEQA/AORjxtIEWeZ2H7KY0HGY4KXjdpMN2OyedoFu3UkvcWWLMKMLiaNlDnt0VfKXgeS64E/IUmiBqnmFPimaUAh0u4Cl2oCQBKCo5zxNcpZ1GD1paGkaZP5VMvAVi0rURUShWaMhHliOJQkMzSIjqnsM1z9x+f0s7O25TKJj7R6u56fPZBLyHUY2buO60MABAi4vsqF/mBgE2rlwABdv1UjWwKJlK8R7XEOjk78KdWltXPRLyZmkGD5qmkgMGicj75CtkdrNcj/f0kD+WianopnVMbuMfhSOP1IFAEwhM8FvdCkw4qJ9k3F6R7IQimKPSnXKEJgqWH0+5WrAA7Jd+VCFIocK/wBLufZCEGkZ3uDyATuhCFoP/9k=' x='0' y='0' height='100%25' width='100%25'/%3E%3C/svg%3E%0A") top center no-repeat;
  background-position: top center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .hero-progressive-image-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.resources-btn {
  background-color: #373e45;
  color: white;
  margin: auto;
  line-height: 1em;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  transition: all 0.1s linear;
  padding: 13px 26px;
  display: inline-block;

  &:hover {
    text-decoration: none;
    color: #45c49e;
  }
}

button.btn.btn-link {
  text-decoration: none;

  &.dark-text {
    color: #212529;
    transition: all 0.15s linear;

    &:hover {
      color: darken(#212529, 10);
      text-decoration: none;
    }

    &:focus {
      color: #212529;
      text-decoration: none;
    }
  }
}

/* NGx Image Loader Customizations */
.ngx-image-placeholder {
  overflow: initial;
  img {
    &.fadeInUp {
      transform: translateY(5%);
      transition:
        opacity 1s linear,
        transform 1s linear;
    }

    &.fadeInDwn {
      transform: translateY(-5%);
      transition:
        opacity 1s linear,
        transform 1s linear;
    }

    &.fadeInLeft {
      transform: translateX(5%);
      transition:
        opacity 1s linear,
        transform 1s linear;
    }

    &.fadeInRight {
      transform: translateX(-5%);
      transition:
        opacity 1s linear,
        transform 1s linear;
    }

    &.loaded {
      &.fadeInUp,
      &.fadeInDwn {
        transform: translateY(0);
      }

      &.fadeInLeft,
      &.fadeInRight {
        transform: translateX(0);
      }
    }
  }
}
.no-placeholder {
  .placeholder-loading-image {
    display: none;
  }
}
.bg-image {
  .ngx-image-placeholder {
    top: 0px;
    left: 0px;
    width: auto;
    height: 100%;
    object-fit: cover;
    object-position: center center;

    img {
      // width: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}
.braintree-dropin label {
  display: block;
}

.form-group {
  margin-bottom: 1rem;
}

.has-error {
  .form-control {
    border-color: #d64545 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  &.form-control {
    border-color: #d64545 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }
}

/*Password Requirements Info*/

.bg-white {
  background-color: #fff;
}

.pwviewer {
  float: right;
  position: relative;
  margin-top: -30px;
  margin-right: -18px;
  z-index: 1;
  cursor: pointer;
}

.passwordEye {
  position: relative;
  left: -2em;
  top: -0.5em;
}

.pwd-info-header {
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
}

#pwd_info_list {
  padding-left: 0;
}

#pwd_info_list li {
  list-style-type: none;
  text-align: left;
  margin: 0 auto;
}

#pwd_info_list li.invalid {
  padding-left: 22px;
  background: url("/assets/img/shared/silk_cross.png") no-repeat 0 50%;
}

#pwd_info_list li.invalid,
#pwd_info_list li.invalid * {
  color: #ec3f41 !important;
  line-height: 1.5em !important;
}

#pwd_info_list li.valid {
  padding-left: 22px;
  background: url("/assets/img/shared/silk_accept.png") no-repeat 0 50%;
}

#pwd_info_list li.valid,
#pwd_info_list li.valid * {
  color: #3a7d34 !important;
  line-height: 1.5em !important;
}

/* Silk icons obtained via Creative Commons from: http://www.famfamfam.com/lab/icons/silk/ */

#LoginGetStarted {
  text-align: center;
  margin-top: 15px;
}
.LoginGetStartedText {
  color: white;
  font-size: 1.2em;
}
.LoginGetStartedLink {
  text-decoration: none;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}

.loadingOverlay {
  height: 100vh;
  width: 100vw;
  opacity: 0.8;
  // display: none;
  position: fixed;
  background-color: white;
  z-index: 1002;

  p {
    padding-top: 40vh;
    //padding-left: calc(50% - 110px);
    right: 0;
    font-size: 20px;
    // font-weight: bold;
  }

  .ajax-loader {
    padding-right: calc(50% - 42px);
    float: right;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 0;
  }
}

#sidebarCollapse {
  padding: 0;

  & > div {
    height: 32px;
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .line {
      height: 2px;
      width: 20px;
      background: #fff;
      transition: all 0.5s ease;
    }

    .lineTop {
      transform-origin: top left;
      margin-bottom: 5px;
    }
    .lineMiddle {
      opacity: 1;
    }
    .lineBottom {
      transform-origin: top left;
      margin-top: 5px;
    }
  }

  &.collapsebutton {
    text-align: center;
    background-color: transparent !important;
    color: white;
    border: none;
    outline: 0;
    // padding-left: 9px!important;
    // padding-top: 4px !important;
    transition: all 0.5s ease;

    // .fa.collapseButtonBars {
    //     font-weight: 400;
    //     font-size: 14px;
    //     transform: scale(1.75,1.75);
    // }
  }

  &.open {
    .lineTop {
      transform: rotate(45deg);
    }
    .lineMiddle {
      opacity: 0;
      transform: translateX(-16px);
    }
    .lineBottom {
      transform: translateX(-1px) rotate(-45deg);
    }
  }
}

.noScroll {
  // position: fixed !important;
  overflow: hidden;
}

.topnav #sidebarCollapse {
  padding: 0 6px;

  & > div {
    height: 27px;
  }
}

.plusMinus {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.35s ease-in-out;

  &.small {
    height: 1rem;
    width: 1rem;
    margin-top: 0.1rem;

    .line {
      height: 2px;
      width: 0.75rem;
    }
  }

  &.white {
    .line {
      background: #fff !important;
    }
  }

  .line {
    height: 2px;
    width: 1rem;
    background: #333;
    transition: all 0.35s ease-in-out;
  }

  .lineTop {
    transform-origin: center;
    // margin-bottom: 5px;
  }
  // .lineMiddle {
  //     opacity: 1;
  // }
  .lineBottom {
    transform-origin: center;
    transform: rotate(90deg) translateX(-2px);
  }
}

.open {
  .plusMinus {
    transform: rotate(90deg);
    .lineTop {
      transform: rotate(90deg);
    }
  }
}

.toast {
  background-color: rgba(255, 255, 255, 0.98);
  cursor: pointer;
  box-shadow:
    rgba(12, 12, 12, 0.4) 0px 0px 1px 0px,
    rgba(31, 31, 31, 0.36) 0px 10px 20px 0px;

  .bg-mentoring {
    background-color: lighten(#5db382, 4);
  }
  .bg-pioneering {
    background-color: lighten(#bf393d, 4);
  }
  &.info {
    border-left: 5px solid rgb(66, 72, 84);
  }
  &.success {
    border-left: 5px solid #5db382;
  }
  &.danger {
    border-left: 5px solid #bf393d;
  }
}

.bg-mentoring {
  background-color: #5db382;
}
.bg-pioneering {
  background-color: #bf393d;
}
.bg-aligning {
  background-color: #85669e;
}
.bg-championing {
  background-color: #e5b25d;
}
.bg-teaching {
  background-color: #7890cc;
}

.mentoring-color {
  color: #44c59f;
}

.pioneering-color {
  color: #bf393d;
}

.aligning-color {
  color: #85669e;
}

.championing-color {
  color: #e5b25d;
}

.teaching-color {
  color: #7890cc;
}
// }

// #loader {
//     position: relative;
//     // left: 48%;
//     z-index: 1;
//     width: 20px;
//     height: 20px;
//     border: 5px solid #f3f3f3;
//     border-radius: 50%;
//     border-top: 5px solid #102A43;
//     -webkit-animation: spin 2s linear infinite;
//     animation: spin 2s linear infinite;
// }

// @-webkit-keyframes spin {
//     0% {
//         -webkit-transform: rotate(0deg);
//     }

//     100% {
//         -webkit-transform: rotate(360deg);
//     }
// }

// @keyframes spin {
//     0% {
//         transform: rotate(0deg);
//     }

//     100% {
//         transform: rotate(360deg);
//     }
// }

.popover-dark {
  background-color: #424854;

  .popover-body {
    color: #fff;
  }

  .arrow:after {
    border-top-color: #424854;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 525px;
  }
}

.card.insights-card {
  border-top: 5px solid $brand-green;
  margin: auto;
  margin-bottom: 25px;
  height: 100%;
  max-width: 800px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .strengths,
  .blindspots,
  .teamDynamics {
    list-style: none;
  }

  ul.strengths,
  ul.blindspots,
  ul.teamDynamics {
    padding-inline-start: 70px;
    @media only screen and (max-width: 767px) {
      padding-inline-start: 10px;
    }
  }

  .category-header {
    display: flex;
    padding: 15px 15px 0px 15px;
    align-items: center;
  }

  .insights-category {
    color: $brand-green;
    font-weight: 700;
    // margin-top: 18px;
    // margin-bottom: 16px;
    padding-left: 15px;

    @media only screen and (max-device-width: 991px) {
      padding-left: 10px;
    }
  }

  .insights-description {
    // margin-left: 15px;

    .insights-description-inner {
      margin: 12px;

      /* This creates spacing around the insights lists that are series of uls for PDF output. */
      &.plain-content {
        padding: 18px;
      }
    }

    .insights-description-inner > b:after {
      content: "\a";
      white-space: pre;
    }

    .insights-description-inner > ul:last-of-type {
      margin-bottom: 20px;
    }

    .insights-description-inner > br {
      display: none;
    }
  }

  ul.teamDynamics {
    padding-inline-start: 0;
    padding-left: 0;

    .insights-description .insights-description-inner > b {
      font-size: 26px;
      margin: 20px 0 10px 0;

      &:first-of-type {
        display: none;
      }

      &:nth-of-type(2) {
        margin-top: 5px;
      }
    }
  }
}

.ql-container {
  height: 350px;
}

.spinner-border-md {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.2em;
}

button.no-focus:focus {
  outline: none !important;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(103 116 129 / 18%);
}

.table {
  td,
  th {
    vertical-align: middle;
  }
}

.mt-5px {
  margin-top: 6px !important;
}

.onoffswitch {
  position: relative;
  width: 40px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: all 0.15s linear;
}
.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 15px;
  padding: 0;
  line-height: 15px;
  border: 0px solid #ffffff;
  border-radius: 20px;
  background-color: #9e9e9e;
  transition: all 0.15s linear;
}
.onoffswitch-label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin: -2.5px;
  background: #ffffff;
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 23px;
  border-radius: 20px;
  box-shadow: 0 3px 10px 0px #b5b5b5;
  transition: all 0.25s linear;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #404b55;
  transition: all 0.15s linear;
}
.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: #404b55;
  transition: all 0.15s linear;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
  transition: all 0.15s linear;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0px;
  background-color: #404b55;
  box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
  // transition: all 0.15s linear;
}

.badge-mpact {
  color: #fff;
  background-color: #43c59e;
}

.ivy-header {
  font-family: ivymode, proxima-nova;
  font-weight: 600;
}

.page-container {
  margin-bottom: -108px;
}

.pointer {
  cursor: pointer;
}

@media print {
  body {
    min-width: initial !important;
    color: #292929;
  }

  div#Embed {
    display: none !important;
  }

  li {
    line-height: 1.25 !important;
    margin-bottom: 5px !important;
  }

  // *, *::before, *::after {
  //     text-shadow: inherit !important;
  //     box-shadow: inherit !important;
  // }

  @page {
    margin: 0;
    padding: 0;
    size: 8.5in 11in;

    // counter-increment: page;
  }

  .page {
    counter-increment: page;
  }

  body {
    counter-reset: page;
  }

  .pagination {
    // position: fixed;
    // top: 0;
    display: inline-block;
    margin-left: auto;
  }

  .pagination::before {
    content: counter(page);
  }

  .toc-text.team-dynamics {
    br {
      display: none !important;
    }

    > b {
      display: block;

      font-size: 13pt;
      line-height: 16pt;
      font-weight: 600;

      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.1)
      );
      border-radius: 12px;

      padding: 4px 12px;

      margin-top: 1rem;

      &:first-of-type {
        display: none !important;
      }
    }

    > p {
      padding-top: 0.25rem;
      padding-left: 0.75rem;

      margin-bottom: 0.5rem;

      font-size: 11pt;
      line-height: 15pt;

      // &:last-child {
      //     margin-bottom: 1rem;
      // }
    }
  }
}

.wony-card,
.wyno-card,
.otog-card {
  br {
    display: none;
  }

  ul {
    margin-bottom: 0;
    list-style: none;

    li {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M77,23V77H23V23H77M91,9H9V91H91V9Z' style='fill:%23939393'/%3E%3C/svg%3E");
        content: "";
        margin: 0px 5px 0 -21px;
        background-size: 1rem 1rem;
        width: 1rem;
        height: 1rem;
        display: inline-block;
        top: 3px;
        position: relative;
      }
    }
  }

  .description {
    b {
      display: block;

      &:nth-of-type(2) {
        color: #292929;
      }

      &:nth-of-type(3) {
        margin-top: 0.5rem;
      }
    }

    &.q-1 b:nth-of-type(1) {
      color: #85669e;
    }

    &.q-2 b:nth-of-type(1) {
      color: #bf393d;
    }

    &.q-3 b:nth-of-type(1) {
      color: #7890cc;
    }

    &.q-4 b:nth-of-type(1) {
      color: #5db382;
    }

    &.q-5 b:nth-of-type(1) {
      color: #e5b25d;
    }
  }
}

.page {
  height: 11in;
  width: 8.5in;
  // background-color: #989898;
  padding: 0.5in;

  &--cover {
    padding: 0.5in;
  }
}

@page {
  margin: 0;
  padding: 0;
  size: 8.5in 11in;

  counter-increment: page;
}

@media print {
  .page {
    page-break-after: always;
  }

  pdf-page-footer {
    page-break-before: avoid;
  }

  .insight-content {
    p {
      margin-bottom: 0.5rem !important;

      &:last-of-type {
        margin-bottom: 0.75rem !important;
      }

      &:only-of-type {
        margin-bottom: 0.25rem !important;
      }
    }

    .row {
      ul {
        margin-bottom: 0.5rem;
        margin-block-start: 0.25rem;
        // padding-inline-start: 20px;
      }

      &:last-of-type {
        ul {
          margin-bottom: 0 !important;
        }
      }
    }

    .col-10 {
      padding-left: 0.5rem;
    }
  }
}

ul.ais-Pagination-list {
  justify-content: center;
  margin-bottom: 1rem;
}

.input-group-prepend button.btn.btn-light {
  height: 100%;
}

#resourcesPageNav,
#resourcesMobPageNav {
  a {
    text-decoration: none;
  }
}

.sd-progress {
  height: 0.625rem;
}

.sd-progress__text {
  display: none;
}

.sd-selectbase {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.sd-radio {
  border: 2px solid #fff;
  border-radius: 6px;
  padding: 0;
  transition: all 0.15s ease-in-out;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    border: 2px solid #43c59e;
  }
}

.sd-radio--checked {
  background-color: #43c59e;
  color: #fff;
  border: 2px solid #43c59e;

  .sd-item__control-label {
    color: #fff;
  }
}

.sd-radio__decorator {
  display: none;
}

.sd-item__control-label {
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  transition: all 0.15s ease-in-out 0s;
  font-family: proxima-nova;

  &:hover {
    cursor: pointer;
  }
}

.sd-element__title .sv-string-viewer {
  font-size: 1.5rem;
}

.sd-body.sd-body--static {
  max-width: calc(100 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

#sv-nav-next,
#sv-nav-complete {
  margin-left: auto;
}

.sd-title,
.sd-page,
.sd-html,
.sd-button,
.sd-html p,
.sd-btn {
  font-family: proxima-nova !important;
}
